exports.components = {
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-monark-historia-js": () => import("./../../../src/pages/monark-historia.js" /* webpackChunkName: "component---src-pages-monark-historia-js" */),
  "component---src-pages-nyheter-js": () => import("./../../../src/pages/nyheter.js" /* webpackChunkName: "component---src-pages-nyheter-js" */),
  "component---src-pages-print-js": () => import("./../../../src/pages/print.js" /* webpackChunkName: "component---src-pages-print-js" */),
  "component---src-pages-reklam-js": () => import("./../../../src/pages/reklam.js" /* webpackChunkName: "component---src-pages-reklam-js" */),
  "component---src-pages-rex-historia-js": () => import("./../../../src/pages/rex-historia.js" /* webpackChunkName: "component---src-pages-rex-historia-js" */),
  "component---src-pages-vad-saknas-js": () => import("./../../../src/pages/vad-saknas.js" /* webpackChunkName: "component---src-pages-vad-saknas-js" */),
  "component---src-templates-foremal-js": () => import("./../../../src/templates/foremal.js" /* webpackChunkName: "component---src-templates-foremal-js" */)
}

